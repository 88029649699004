

function AdminMenu(params){

    return(
        <div className="AdminMenu">
            <button onClick={()=>params.setView("documents")}>Document Download</button>
            <button>Stats</button>
            <button>Logs</button>
            <button onClick={()=>params.setView("promo")}>Promos</button>
            <button onClick={()=>params.setView("ContactRequests")}>Contact Requests</button>
            <button onClick={() => params.setLogin(false)}>Sign Out</button>
        </div>
    )
}

export default AdminMenu;