import Service from '../Component/Service'
import { useEffect, useState } from 'react'
import axios from "axios"
import '../Styles/Services.css'
import SPAService from '../Component/SPAService'
import businessImage from '../Assets/Businesstaxinfo.png'
import personalImage from '../Assets/Personaltaxinfo.png'
import ptax from '../Assets/PersonalTaxes.jpg'
import btax from '../Assets/BusinessTax.jpg'
function Services(params) {
    const [Services, setServices] = useState([{ name: "Business Taxes", description: "Running a small business cna be quite a hassle especially when it comes to tax season. with small business tax preparation from Anderson's Taxes, our trained professional can guarantee a hassle-free tax season for you and your small business. Contact us today to find out more.", download: businessImage ,image: btax}, { name: "Personal Taxes", description: "We all know filing taxes can be confusing, time consuming and frustrating. But here at Anderson's Taxes, our trained professional has years of tax preparing experience that will ensure your personal tax preparation is done quickly and correctly. Contact us today to find out more.", download: personalImage, image: ptax}])
    // useEffect(() => {
    //     axios.get("http://127.0.0.1:5000/services")
    //         .then(response => {
    //             setServices(response.data)
    //         })
    // }, [])

    console.log(Services)
    return (
        <div className="Services">
            <h1 style={{textAlign: 'center'}}>We offer these Services!</h1>

            {/* {Services.map((service,idx)=><>
        <Service id = {service[0]} name={service[1]} description = {service[2]} service = {service[0]}></Service>
        </>)} */}

            {Services.map((service, idx) => <>
                <SPAService name={service.name} description={service.description} download={service.download} image={service.image}></SPAService></>
            )}

        </div>
    )
}

export default Services;