import ContactForm from "../Component/ContactForm";


function ContactUs(params){


    return(
        <div className="ContactUs" style={{textAlign:"center"}}>
            <h2>Contact our amazing representative today!</h2>
            <ContactForm/>
            <h3>We Do it for you!</h3>
        </div>
    )
}

export default ContactUs;