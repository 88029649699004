import '../Styles/Services.css'
function SPAService(params) {

    return (
        <div className="ServiceConatiner">
            <img src={params.image}></img>
            <h2>{params.name}</h2>
            <h3>{params.description}</h3>
            <a href={params.download} download={params.download}><button>Download Info</button></a>
        </div>
    )
}

export default SPAService;