import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from './Page/Home';
import Menu from './Component/Menu';
import DiscountBanner from './Component/DiscountBanner';
import NoPage from './Page/NoPage';
import AboutUs from './Page/AboutUs';
import Footer from './Component/Footer';
import ContactUs from "./Page/ContactUs";
import Services from './Page/Services';
import UploadDocuments from './Page/UploadDocuments';
import Admin from './Page/Admin';
import axios from 'axios'
import Login from './Page/Login'
import { useEffect, useState } from "react";

function App() {
  // const [banner, setBanner] = useState(null)
  const [login, setLogin] = useState(false)
  const paths = ["Home", "AboutUs", "ContactUs", "Services"]
  const [path, setPath] = useState("Home")

  // const BaseUrl = "http://127.0.0.1:5000/" 
  // useEffect(() => {
  //   axios.get(BaseUrl+"promotions")
  //     .then(response => {
  //       if (response.data)
  //         setBanner(response.data[2])
  //     })
  // }, [])
  // console.log(banner)
  return (
    <>
      <header>
        {/* <DiscountBanner discount={banner}></DiscountBanner> */}
        <Menu setPath={setPath}></Menu>
      </header>
      <div className="b">
        {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Admin" element={login ? <Admin login={login} setLogin={setLogin} baseUrl={BaseUrl}/> : <Login login={setLogin} baseUrl={BaseUrl}></Login>} />
          <Route index element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/UploadDocuments" element={<UploadDocuments />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter> */}

        {path == "Home" && <AboutUs setPath={setPath}/>}
        {path == "AboutUs" && <AboutUs setPath={setPath}></AboutUs>}
        {path == "ContactUs" && <ContactUs></ContactUs>}
        {path == "Services" && <Services></Services>}
        {path == "Documents" && <UploadDocuments></UploadDocuments>}
      </div>
      <Footer></Footer>
    </>
  );
}

export default App;
