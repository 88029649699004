

function FileCard(params) {

    return (
        <div className="FileCard">
            <h4>{params.filename}</h4>
        </div>
    )
}

export default FileCard;