import { useState } from "react"
import axios from "axios"
const documents = ["W-2", "Driver's License", "Social Security Card",
    "Dependents Birth Certificate(s)", "1099-INT",
    "1098-T", "1098 Mortgage Interest Statement",
    "1099-SA (HSA)", "Donation Statement"]

function DocumentUploadEmailForm(params) {
    const [view, setView] = useState(true)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [bank, setBank] = useState("")
    const [routing, setRouting] = useState("")
    const [bankNumber, setBankNumber] = useState("")
    const [deposit, setDeposit] = useState("Checking")

    const handleClick = (e) => {
        e.preventDefault();
        window.open(`mailto:Tawnee.Anderson@andersonstaxes.com?subject=${name}&body=${"Name: "+name+
        " %0D%0APhone: "+phone+
        " %0D%0ABank Name: "+bank+
        " %0D%0ABank Number: "+bankNumber+
        " %0D%0ARouting Number: "+routing+
        " %0D%0ADeposit Type: "+deposit+
        " %0D%0A %0D%0A %0D%0AFiles to send "+ createDocList()+
        "%0D%0A You may need to send more than one email for all of the files needed."
    }`);
    }
    const createDocList=()=>{
        let docList = ""
        documents.map((doc)=>{
            docList= docList+doc+"%0D%0A"
        })
        return docList
    }
    console.log(name)
    return (
        <div className="ContactForm">
            <h2>Use this form to email your Documents to Anderson Taxes</h2>
            <form action={handleClick} method="get" enctype="text/plain">
                <label onClick={() => setView(!view)}>Don't know what Documents to send. Here is a list of Documents we normally need . . .<br></br> Click me to see {view ? "less" : "more"}</label>
                {view &&
                    documents.map((type, key) =>
                        <ul style={{ textDecoration: "none" }} key={key}>
                            <li style={{ textDecoration: "none" }}>{type}</li>
                        </ul>
                    )
                }
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name"></input>
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone Number" ></input>
                <input
                    type="text"
                    name="bank"
                    id="bank"
                    value={bank}
                    onChange={(e) => setBank(e.target.value)}
                    placeholder="Bank Name" ></input>
                <input
                    type="text"
                    name="routing"
                    id="routing"
                    value={routing}
                    onChange={(e) => setRouting(e.target.value)}
                    placeholder="Routing Number" ></input>

                <input
                    type="text"
                    name="bankNumber"
                    id="bankNumber"
                    value={bankNumber}
                    onChange={(e) => setBankNumber(e.target.value)}
                    placeholder="Bank Account Number" ></input>

                <label for="Checking">Checking</label>
                <input type="radio" name="checking" id="checking" value="Checking" onChange={(e)=>setDeposit(e.target.value)}></input>
                <br></br>
                <label for="Saving">Saving</label>
                <input type="radio" name="saving" id="saving" value="Saving" onChange={((e)=>setDeposit(e.target.value))}></input>
                <input type="submit" value="Open email to attach documents." onClick={handleClick} />
            </form>

        </div>)
}

export default DocumentUploadEmailForm;


