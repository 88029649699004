import { useState } from 'react';
import '../Styles/Menu.css'
import hamburger from '../HamburgerIcon.png'
import closed from '../Close.png'
import '../Styles/Hamburger.css'
import logo from '../Logo.png'
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";

function Menu(params) {
    const [clicked, setClicked] = useState(false)
    return (
        <div className="Menu">
            <a href='/'>
                <img src={logo} alt="Anderson Taxes Logo" />
            </a>
            <h1>Anderson's Taxes</h1>
            {clicked &&
                <div className="Hamburger"><GiHamburgerMenu onClick={() => setClicked(!clicked)} /></div>
            }
            {!clicked && <div className='Hamburger'><VscChromeClose onClick={() => setClicked(!clicked)} /></div>}
            <div className="MenuPages">

                {/* <div className="MenuPageLink"><a href="/aboutUs">About Us</a></div>
                <div className="MenuPageLink"><a href="/Services">Services</a></div>
            <div className="MenuPageLink"><a href="/UploadDocuments">Documents</a></div>
            <div className="MenuPageLink"><a href="/ContactUs">Contact</a></div> */}
                <div className="MenuPageLink"><a onClick={() => params.setPath("AboutUs")}>About Us</a></div>
                <div className="MenuPageLink"><a onClick={() => params.setPath("Services")}>Services</a></div>
                <div className="MenuPageLink"><a onClick={() => params.setPath("ContactUs")}>Contact</a></div>
                <div className="MenuPageLink"><a onClick={() => params.setPath("Documents")}>Documents</a></div>
            </div>
            {!clicked &&
                <div className="MenuPagesMobile">
                    {/* <div className="MenuPageLinkMobile"><a href="/aboutUs">About Us</a></div>
                    <div className="MenuPageLinkMobile"><a href="/Services">Services</a></div>
                    <div className="MenuPageLinkMobile"><a href="/UploadDocuments">Documents</a></div>
                    <div className="MenuPageLinkMobile"><a href="/ContactUs">Contact</a></div> */}
                    <div className="MenuPageLinkMobile"><a onClick={() => params.setPath("AboutUs")}>About Us</a></div>
                    <div className="MenuPageLinkMobile"><a onClick={() => params.setPath("Services")}>Services</a></div>
                    <div className="MenuPageLinkMobile"><a onClick={() => params.setPath("Documents")}>Documents</a></div>
                    <div className="MenuPageLinkMobile"><a onClick={() => params.setPath("ContactUs")}>Contact</a></div>
                </div>
            }

        </div>
    )
}

export default Menu;