import { useState } from "react"
import axios from "axios"

function ContactForm(params) {
    const [name, setName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [question, setQuestion] = useState(undefined)

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "name") {
            setName(value)
        }
        if (name === "email") {
            setEmail(value)
        }
        if (name === "question") {
            setQuestion(value)
        }
        else {
            console.error("invalid name")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name)
        console.log(email)
        console.log(question)
        e.preventDefault();
        window.open(`mailto:Tawnee.Anderson@andersonstaxes.com?subject=${name}&body=${"Name: "+name+
        " %0D%0AEmail: "+email+
        " %0D%0A"+question
    }`);
        // axios.put(`http://127.0.0.1:5000/contact`,
        //     { name: name, email: email, question:question }
        // )
        //     .then((res) => {
        //         if (res.data.success === true) {
        //             alert(`Successfully Submitted Contact Request`)
        //             setName(undefined)
        //             setEmail(undefined)
        //             setQuestion(undefined)
        //         }
        //         else {
        //             alert("Failed to Submitted Contact Request")
        //         }
        //     }) // Handle the response from backend here
        //     .catch((err) => { console.log(err) }); // Catch errors if any
    }

    return (
        <div className="ContactForm">
            <form onSubmit={handleSubmit}>
            <label>Your Name</label>
            <input type="text" name="name" value={name || ""} onChange={handleChange} required></input>
            <label>Your Email</label>
            <input type="text" name="email" value={email || ""} onChange={handleChange} required></input>
            <label>Your Question</label>
            <textarea type="text" name="question" value={question || ""} onChange={handleChange} required></textarea>
            <input type="submit" onClick={handleSubmit}></input>
        </form>
            {/* <form action="mailto:Tawnee.anderson@andersonstaxes.com" method="get" enctype="text/plain">
                <input type="text" name="subject" placeholder="Your Name"></input>
                <input type="email" name="email" placeholder="Your Email" />
                <textarea name="body" rows="5" cols="30" placeholder="Your Message"></textarea>
                <input type="submit" value="Send" />
            </form> */}

        </div>)
}

export default ContactForm;


