


import axios from 'axios'

const { useState, useEffect } = require("react")

function AdminPromo(params) {
    const [page, setPage] = useState("Add")
    const [promos, setPromos] = useState([])
    const baseUrl = "http://127.0.0.1:5000/"

    const handleChangePromoStatus = (e) => {
        e.preventDefault()
        axios.patch(baseUrl + "promotions", { promoId: e.target.id, active: e.target.name })
            .then((res) => {
                console.log(res.data.success)
                axios.put(baseUrl + "promotions").then((res) => {
                    setPromos(res.data.promos)
                })
                    .catch((err) => {
                        console.log(err)
                    })
            })
    }

    useEffect(() => {
        axios.put(baseUrl + "promotions").then((res) => {
            setPromos(res.data.promos)
        })
            .catch((err) => {
                console.log(err)
            })
    }, [page])

    const handleSubmitAddPromo = (e) => {
        e.preventDefault();
        const promo = e.target.promo.value;
        axios.post(baseUrl + "promotions", { promo: promo }).then((res) => {
            if (res.data.success) {
                console.log(res.data.success)
            } else {
                console.log(res.data.success)
            }
        })
        e.target.promo.value=""
    }

    return (
        <div className="AdminPromo">
            AdminPromo
            <div className="AdminPromoMenu">
                <button onClick={() => setPage("Add")}>Add Promo</button>
                <button onClick={() => setPage("Cancel")}>Activate/Deactivate Promo</button>
            </div>
            {page === "Add" &&
                <div className="AdminPromoAddForm">
                    <form onSubmit={handleSubmitAddPromo}>
                        <label>
                            <input type="text" name="promo" required></input>
                        </label>
                        <input type="submit"></input>
                    </form>
                </div>
            }
            {page === "Cancel" &&
                <div className="AdminPromoCancelTable">
                    <table>
                        <thead>
                            <tr>
                                <td>Promo</td>
                                <td>Active</td>
                                <td>Activate/Deactivate</td>
                            </tr>
                        </thead>
                        <tbody>
                            {promos.map((promo) => <tr key={promo[0]}><td>{promo[2]}</td><td>{promo[1] ? "Active" : "Deactivated"}</td><td><button onClick={handleChangePromoStatus} id={promo[0]} name={promo[1]}>{promo[1] ? "Deactivate" : "Activate"}</button></td></tr>)}
                        </tbody>
                    </table>
                </div>}
        </div>
    )
}

export default AdminPromo