import { useState } from "react";
import UploadForm from "../Component/DocumentUploadForm";
import DocumentUploadEmailForm from "../Component/DocumentUploadEmailForm";


function UploadDocuments(params){
const[isAlive,setIsAlive]=useState(false)
    if (isAlive){
        return(
            <div className="UploadDocuments">
                <UploadForm></UploadForm>
            </div>
        )
    }
    else{
        return(
        <div className="UploadDocuments" style={{textAlign:"center"}}>
                <DocumentUploadEmailForm></DocumentUploadEmailForm>
            </div>
        )
    }
   
}

export default UploadDocuments;