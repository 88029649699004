import { useState } from "react";
import FileCard from "./FileCard";
import axios from 'axios'
// import {useNavigate} from 'react-router-dom'
import '../Styles/DocumentsForm.css'

function UploadForm(params) {
    const [files, setFiles] = useState([])
    // const navigate = useNavigate()

    function createErrorMsg(error) {
        let msg = ""
        error.map((err) => {
            msg = msg + "\n" + err.msg
        })
        return msg
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const fname = e.target.fname.value
        const lname = e.target.lname.value
        const email = e.target.email.value
        const phone = e.target.phone.value
        const address = e.target.address.value
        const zip = e.target.zip.value;
        const city = e.target.city.value;
        const state = e.target.state.value;
        const birth = e.target.birth.value;

        console.log(`${fname} ${lname}`)
        let formData = new FormData()
        for (let x = 0; x < files.length; x++) {
            formData.append(`Document${x}`, files[x])
        }
        axios({
            // Endpoint to send files
            url: `http://127.0.0.1:5000/documents?firstName=${fname}&lastName=${lname}&email=${email}&phone=${phone}&address=${address}&zip=${zip}&city=${city}&state=${state}&birth=${birth}`,
            method: "POST",
            headers: {
                // Add any auth token here
                "authorization": "your token comes here",
                'Accept': 'application/json',
                "Content-type": "multipart/form-data",
            },
            // Attaching the form data
            data: formData,
        })
            .then((res) => {
                if (res.data.Success === true) {
                    alert("Successfully uploaded Documents.")
                    setFiles([])
                    window.location.reload(false);
                }
                else {
                    let msg = createErrorMsg(res.data)
                    alert(msg)
                }
            }) // Handle the response from backend here
            .catch((err) => { console.log(err) }); // Catch errors if any
    }

    const handleFileAdd = (e) => {
        if (e.target.files) {
            setFiles([...files, e.target.files[0]])
        }
    }

    const removeFile = (e) => {
        e.preventDefault()
        let fileName = e.target.id
        let cleanedFiles = files.filter(item => item.name !== fileName)
        setFiles(cleanedFiles)
    }

    return (<>
        <form autoComplete="on" onSubmit={handleSubmit} className="DocumentsUpload">
            <h3>Upload your Documents Here!</h3>
            <label>First Name<input type="text" autoCapitalize="on" name="fname" required></input></label>
            <label>Last Name<input type="text" autoCapitalize="on" name="lname" required></input></label>
            <label>Email<input type="email" autoCapitalize="on" name="email" required></input></label>
            <label>Phone Number<input type="phone" autoCapitalize="on" name="phone" required></input></label>
            <label>Birth Date<input type="date" name="birth" required></input></label>
            <label>Address<input type="address" name="address" required></input></label>
            <label>Zip Code<input type="text" pattern="[0-9]{5}" name="zip" required></input></label>
            <label>City<input type="text" autoCapitalize="on" name="city" required></input></label>
            <label>State<input type="text" autoCapitalize="on" name="state" required></input></label>
            {
                files.length === 0 ? <input type="file" onChange={handleFileAdd} required accept=".txt,.png,.jpg,.pdf"></input>
                    : <>
                        {files.map((file) => <><FileCard filename={file.name}></FileCard><button id={file.name} onClick={removeFile}>Remove File</button></>)}
                        <label>Upload Another File: </label>
                        <input type="file" onChange={handleFileAdd} value={""} accept=".txt,.png,.jpg,.pdf"></input>
                    </>
            }

            <input type="submit"></input>
        </form>
    </>
    )
}

export default UploadForm;