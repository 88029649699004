import taxImage1 from "../Assets/taximage1.jpg"
import taxImage2 from "../Assets/taximage2.jpg"
import taxImage3 from "../Assets/taximage3.jpg"
import taxImage4 from "../Assets/taximage4.jpg"
import taxImage5 from "../Assets/taximage5.jpg"
import TawneeAndersonImage from "../Assets/TawneeAndersonPro.jpg"
function Home() {
  return (
    <div className="Home">
      <img src={taxImage1}></img>
      <img src={taxImage2}></img>
      <img src={taxImage3}></img>
      <img src={taxImage4}></img>
      <img src={taxImage5}></img>
      <img src={TawneeAndersonImage}></img>
    </div>
  );
}

export default Home;
